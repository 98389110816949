<template>
  <div>
      
      <el-button type="primary" style="margin-bottom: 20px" @click="toAdd">添加技能</el-button>
      
      <div ref="listBox" class="list-container-box">
          <div class="list-container">
            <el-card v-for="item in mySkillList" :key="'skill' + item.id" class="item-card">
                <div class="item-box">
                    <el-image :src="item.cover_img" class="cover-img" fit="contain"></el-image>
                    <div class="item-content">
                        <div class="item-title" @click="clickItem(item)">{{item.project_desc}}</div>
                        <div class="item-tags" v-if="item.skill_label">
                            <el-tag class="tag-item" size="small" v-for="(tag, index) in (item.skill_label.split(','))" :key="item.id + '-' + index">{{tag}}</el-tag>
                        </div>
                        <div v-if="item.skill_type" class="item-address">{{item.skill_type}}</div>
                        <div class="status">
                            <label class="status-title">审核状态</label>
                            <label class="status-content">{{statusMap[item.check_status]}}</label>
                        </div>
                    </div>
                    <div class="operation">
                        <el-button size="small" type="primary" @click="toEdit(item)" style="margin-right: 15px">编辑</el-button>
                        <el-button size="small" type="default" @click="deleteItem(item)">删除</el-button>
                    </div>
                
                </div>
                
            </el-card>
            <div v-if="!skillHasNext && mySkillList.length > 0" class="bottom-box">已经没有更多数据啦~~</div>
            <el-card 
                v-if="loading"
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(4 4 4 / 0%)"
                class="item-card">
            </el-card>
            <el-card 
                v-if="mySkillList.length === 0 && !loading"
                class="item-card">
                <el-empty v-if="mySkillList.length === 0 && !loading" description="暂无数据 ~~ "></el-empty>
            </el-card>
        </div>
      </div>
      
      
      
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    
    data() {
        return {
            listContainerWidth: 0,
           
            statusMap: {
                "0": "待审核",
                "1": "审核通过",
                "-1": "审核未通过"
            },
            currentCategory: {

            },
            loadPageFlag: true,
        }
    },
    computed: {
        ...mapState({
            loading: (state) => state.user.loading,
            mySkillList: (state) => state.user.mySkillList,
            userInfo: (state) => state.user.user,
        }),
        listContainerStyle() {
            return `width: ${this.listContainerWidth}px;`
        }
    },
    
    mounted() {
    
        this.initListContainerSize()
        this.loadData(true)
    },
    methods: {
        deleteItem(item) {
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios({
                url: '/nrb_service/skill/delete_skill',
                method: 'get',
                params: {
                    id: item.id,
                }
                }).then(res => {
                if (res.data && res.data.code === 'A00000') {
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this.loadData(true)
                } else {
                    this.$message({
                    type: 'error',
                    message: '删除失败!'
                    });
                }
                })
            })
        },
        initPageComponent() {
            let callback = () => {
                this.loadData()
            }

            window.onscroll = function() {
                console.log(document.body)
                
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                
                var clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
                
                var offsetHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
                
                
                if (scrollTop > 5 && (Math.abs(scrollTop + clientHeight - offsetHeight) < 5)) {
            
                    callback()
                }
            }
        },
        initListContainerSize() {
            this.listContainerWidth = this.$refs['listBox'].clientWidth + 20;
        },
        loadData(refresh) {

            this.$nextTick(() => {
                if (this.loadPageFlag) {
                this.loadPageFlag = false
                if (refresh) {
                    this.$store.dispatch("user/getUserInfo").then(() => {
                        this.$store.dispatch("user/loadMySkillList", {refresh: refresh}).then(() => {
                            this.loadPageFlag = true
                            this.initPageComponent()
                        })
                    }) 
                } else {
                    this.$store.dispatch("user/loadMySkillList", {}).then(() => {
                        this.loadPageFlag = true
                    })
                }
               
            }
        })
            
        },
        toEdit(item) {
            this.$store.commit("user/SET_EDIT", true)
            this.$store.commit("user/SET_EDIT_ID", item.id)
        },
        toAdd() {
            
            this.$store.commit("user/SET_EDIT_ID", undefined)
            this.$store.commit("user/SET_EDIT", true)
        }
    }
    

}
</script>

<style lang="scss" scoped>
    .list-container-box {
        overflow: hidden;
        height: auto;
    }
    .list-container {
        overflow-y: hidden;
        overflow-x: hidden;
        height: auto;
    }

    .item-card {
        padding: 20px;
        margin-bottom: 20px;
        
        .cover-img {
            width: 250px;
            height: 150px;
            background: #f5f5f5;
        }

        .item-content {
            flex: 1;
            padding: 0 20px;
            display: flex;
            flex-direction: column;

            .item-title {
               
                font-size: 16px;
                font-weight: bold;
                flex: 1;
            
            }
            
            .item-tags {
               
                flex: 1;
                font-size: 14px;
                color: rgb(93, 170, 243);
                flex: 1;
            }

            .item-address {
                font-size: 14px;
                flex: 1;
            }
        }
    }

    .status {
        display: flex;
        
        flex: 1;

    } 

    .status-title {
        font-size: 16px;
        color: #333;
        background-color: rgb(233, 233, 233);
        padding: 10px;
    }

    .status-content {
        font-size: 16px;
        color: white;
        background-color: orange;
        padding: 10px;
    }

    .item-box {
        display: flex;
    }

    .tag-item {
        margin-right: 15px;
    }
    .bottom-box {
        padding: 20px;
        font-size: 20px;
        color: gray;
        text-align: center;
    }
</style>