<template>
  <div class="base-info-container">
      <el-image class="avartar" :src="avatar"></el-image>
      <div class="info-content">
          <div class="username">
              <div class="section-title">姓名：</div>
              <el-input v-if="editingUsername" class="input-width" placeholder="请输入姓名" size="mini" v-model="username" @blur="saveUsername"></el-input>
              <div v-else class="section-content">{{user.username || '未设置'}}</div>
              <i v-if="!editingUsername" class="el-icon-edit edit-icon" @click="editUsername"></i>
              <i v-if="editingUsername" class="el-icon-circle-check edit-icon" @click="saveUsername"></i>
              <i v-if="editingUsername" class="el-icon-circle-close edit-icon" @click="cancelEdit"></i>
          </div>
          <div class="mobile">
              <div class="section-title">手机号：</div>
              <div class="section-content mobile-content">{{user.mobile}}</div>
          </div>
          <div class="address">
              <div class="section-title">所在城市：</div>
              <l-city class="title" :name="user.address || '地点'" @selectProvince="selectProvince" @selectCity="selectCity" ></l-city>
          </div>
      </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import City from "../city/tree.vue"

export default {
    components: {
    
        "l-city": City,
        
    },
    data() {
        return {
            avatar:require('../../assets/avatar.png'),
            editingUsername: false,
            editingMobile: false,
            editingAddress: false,
            username: '',
            provinceId: -1,
            cityId: -1
            
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user || {},
        }),
    },
    mounted() {
        this.$nextTick(() => {
            this.username = this.user.username
        })
    },
    methods: {
        editUsername() {
            this.editingUsername = true
        },
        saveUsername() {
            let userInfo = { ...this.user }
            userInfo.username = this.username
            this.editingUsername = false
            this.saveUserInfo(userInfo)
        },
        cancelEdit() {
            this.editingUsername = false
        },
        saveUserInfo(user) {
            user.login_from = 'web'
            this.axios({
                method: 'get',
                url: '/nrb_service/user/update_info',
                params: user
            }).then(res => {
            console.log(res)
            if (res.status === 200 && res.data.code === 'A00000') {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.$store.dispatch("user/getUserInfo")
                
            } else {
                this.$message({
                    type: 'error',
                    message: res.data.msg
                });
            }
            })
        },
        selectProvince(province) {
            
        },
        selectCity(position) {
            this.provinceId = position.province.id
            this.cityId = position.city.id
            this.$nextTick(() => {
                let userInfo = { ...this.user }
                userInfo.province_id = this.provinceId
                userInfo.city_id = this.cityId
                
                this.saveUserInfo(userInfo)
            
                this.$store.dispatch("user/getUserInfo")
            })
        }
    }

}
</script>

<style lang="scss" scoped>
    .base-info-container {
        display: flex;

        .input-width {
            width: 120px;
        }

        .edit-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
            line-height: 24px;
            margin-left: 10px;
            color: gray;
            cursor: pointer;
        }

        .avartar {
            background: #edf8f9;
            padding: 10px;
            border-radius: 60px;
            width: 100px;
            height: 100px;
            margin-right: 40px;

        }

        .info-content {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .username {
            display: flex;
            flex: 1;
            padding: 10px;
        }

        .mobile {
            display: flex;
            flex: 1;
            padding: 10px;
        }

        .address {
            display: flex;
            flex: 1;
            padding: 10px;
        }

        .section-title {
            width: 120px;
            text-align: left;
            font-size: 18px;
            color: gray;
        }

        .section-content {
            font-size: 18px;
            color: #333;
        }

        .mobile-content {
            line-height: 24px;
        }
    }
</style>>
