<template>
  <div v-if="editing">
      <el-dialog title="编辑" :visible.sync="editing" width="600px" @close="close" class="dialog" :center="true">

          <el-form :model="form" status-icon :rules="rules" ref="form" class="skill-form"> 
            
                <el-form-item prop="project_desc" label="技能简介" :label-width="formLabelWidth">
                    <el-input class="input-width" placeholder="请输入一句话简介" v-model="form.project_desc"></el-input>
                </el-form-item>
                
                <el-form-item prop="skill_type_id" label="技能分类" :label-width="formLabelWidth" class="category-item">
                    <l-category @selectSecond="selectSecond" :name="currentSecond.name || '请选择分类'" class="display: inline-block"></l-category>
                </el-form-item>
                
                <el-form-item prop="skill_label" label="技能标签" :label-width="formLabelWidth">
                    <div class="tag-item" v-for="(item, index) in tagList" :key="item.name">
                        <el-tag v-if="!item.editing" :key="index" @click="toEditTag(item)">{{item.name}}</el-tag>
                        <el-input v-if="item.editing" :ref="`tagInput${index}`" class="tag-input-width" placeholder="请输入技能标签" v-model="currentTagName" @blur="saveTag(item)" @keyup.enter.native="saveTag(item)" ></el-input>
                        
                        <i v-if="!item.editing" class="el-icon-circle-close close-tag" @click="deleteTag(index)"></i>
                    </div>
                    <i class="el-icon-plus" v-if="showAddTag" @click="toAddTag"></i>
                    
                </el-form-item>
                <el-form-item prop="service_desc" label="服务介绍" :label-width="formLabelWidth">
                    <el-input class="input-width" type="textarea" :rows="3" placeholder="请介绍一下服务的收费模式吧" v-model="form.service_desc"></el-input>
                </el-form-item>
                <el-form-item prop="skill_desc" label="技能介绍" :label-width="formLabelWidth">
                    <el-input class="input-width" type="textarea" :rows="3" placeholder="请输入技能的详细情况吧" v-model="form.skill_desc"></el-input>
                </el-form-item>
                <el-form-item prop="cover_img" label="技能介绍图" :label-width="formLabelWidth" class="cover-item">
                    <el-upload
                    class="upload-demo"
                    drag
                    :show-file-list="false"
                    action="/nrb_service/upload"
                    multiple
                    :on-success="handleUploadSuccess">
                    <el-image v-if="form.cover_img_url" :src="form.cover_img_url" fit="contain" class="avatar" style="width: 358px;height: 180px;"></el-image>
                    <div v-else class="avatar-box">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                        <div>只能上传jpg/png文件，且不超过2M</div>
                        <div>建议尺寸：500px * 300px</div>
                    </div>
                    </el-upload>
                </el-form-item>
                
                <el-form-item prop="works" label="作品展示" :label-width="formLabelWidth" class="work-item">
                    <el-upload
                    class="upload-demo"
                    :show-file-list="false"
                    action="/nrb_service/upload"
                    :on-success="handleUploadContentSuccess">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip">
                        <div class="content-item" v-for="(item, index) in workList" :key="index">
                        <el-image class="content-item-img" :src="item.url" fit="contain"></el-image>
                        <el-image class="delete-bt" src="close.png" @click="deleteImg(index)"></el-image>
                        </div>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                        <div>只能上传jpg/png文件，且不超过4M</div>
                        <div>建议尺寸：500px * 300px</div>
                    </div>
                    
                    </el-upload>
                </el-form-item>


                <div style="text-align: center"><el-button type="primary" @click="save" style="width: 340px; margin-bottom: 15px">保存</el-button></div>
        </el-form>
      </el-dialog>
        
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Category from "../category/tree.vue"

export default {
    components: {
    
        "l-category": Category,
        
    },
    data() {
        return {
            currentTagName: '',
            tagList: [],
            editingTag: false,
            form: {
                cover_img: 0,
                cover_img_url: '',
                works: [],
                project_desc: '',
                skill_label: '',
                service_desc: '',
                skill_desc: '',

            },
            workList: [],
            formLabelWidth: '100px',
            currentSecond: {

            },
            rules: {
                project_desc: [
                    { required: true, message: '请输入技能简介', trigger: 'blur' },
                    { min: 6, max: 32, message: '长度在6到32个字符', trigger: 'blur' }
                ],
                skill_type_id: [
                    { required: true, message: '请选择技能分类', trigger: 'blur' },
                ],
                service_desc: [
                    { required: true, message: '请输入服务介绍', trigger: 'blur' },
                    { min: 16, max: 100, message: '长度在16到100个字符', trigger: 'blur' }
                ],
                skill_desc: [
                    { required: true, message: '请输入技能介绍', trigger: 'blur' },
                    { min: 16, max: 100, message: '长度在16到100个字符', trigger: 'blur' }
                ],
                skill_label:  [
                    { required: true, message: '请输入技能标签', trigger: 'blur' },
                    { min: 1, max: 20, message: '长度在1到20个字符', trigger: 'blur' }
                ],
                cover_img:  [
                    { required: true, message: '请上传技能介绍图', trigger: 'blur' },
                   
                ],
            }
        }

    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            editing: (state) => state.user.editing || false,
            editingId: (state) => state.user.editingId,
        }),
        showAddTag() {
            return this.tagList.every(o => {
                return !o.editing
            })
        }
    },
    watch: {
        editing(val) {
            this.initForm()
            if (val && this.editingId) {
                this.loadDetail()
            }
        }
    },
    
    methods: {
        enterKeyup(index) {
            this.$refs['tagInput'+index].$emit('blur')
        },
        inputing(item, e) {
            console.log(item)
            console.log(e)
        },
        toEditTag(item) {
            this.currentTagName = item.name
            item.editing = true
        },
        toAddTag() {
            this.tagList.push({
                name: '',
                editing: true
            })
            this.currentTagName = ''
        },
        saveTag(item) {
            item.editing = false
            if (this.currentTagName) {
                item.name = this.currentTagName
                
                this.currentTagName = ''
                
                this.form.skill_label = this.tagList.map(o => o.name).join(',')
            }
            
            
        },
        deleteTag(index) {
            this.tagList.splice(index, 1)
            this.form.skill_label = this.tagList.map(o => o.name).join(',')
        },
        deleteImg(index) {
            this.workList.splice(index, 1)
            
        },

        initForm() {
            this.form = {
                cover_img: 0,
                cover_img_url: '',
                works: [],
                project_desc: '',
                skill_label: '',
                service_desc: '',
                skill_desc: '',
                skill_type_id: 0,

            }
            this.workList = []
            this.currentSecond = {}
            this.tagList = []
        },
        loadDetail() {
            this.axios({
                method: 'get',
                url: '/nrb_service/skill/get_skill_info',
                params: {
                    id: this.editingId
                }
            }).then(res => {
            console.log(res)
            if (res.status === 200 && res.data.code === 'A00000') {
                let data = res.data
                this.workList = data.works
                data.works = data.works.map(o => o.id)
                data.cover_img_url = data.cover_img
                data.cover_img = data.cover_img_id
                this.currentSecond = {
                    id: data.skill_type_id,
                    name: data.skill_type
                }
                if (data.skill_label) {
                    let s = ','
                    if (data.skill_label.indexOf(',') < 0) {
                        s = ' '
                    }
                    this.tagList = data.skill_label.split(data.skill_label.indexOf(',') < 0 ? ' ': ',').map(o => {
                        return {
                            name: o,
                            editing: false,
                        }
                    })
                }
                
                this.form = data
                
                
            } else {
                this.$message({
                type: 'error',
                message: res.data.msg
                });
            }
            })
        },
        close() {
            this.$store.commit("user/SET_EDIT", false)
        },
        selectSecond(second) {
            console.log(second)
            this.currentSecond = second
            this.form.skill_type_id = second.id
        },
         handleUploadSuccess(res, file) {
            console.log(res)
            if (res && res.code === 'A00000') {
                this.form.cover_img_url = res.url;
                this.form.cover_img = res.contentId
            }
        },
        handleUploadContentSuccess(res, file) {
            if (res && res.code === 'A00000') {
                this.form.works.push(res.contentId)
                this.workList.push(res)
            }
        },
        save() {
            
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.doSave()
                } else {
                    return false;
                }
            });
        },
        doSave() {
            let data = { ...this.form }
            data.works = data.works.join(',')
            data.user_id = this.user.id
            data.skill_type_id = this.currentSecond.id
            data.skill_label = this.tagList.map(o => o.name).join(',')
            
            
            this.axios({
                method: 'get',
                url: `/nrb_service/skill/${this.editingId > 0 ? 'update_skill': 'add_skill'}`,
                params: data
            }).then(res => {
            console.log(res)
            if (res.status === 200 && res.data.code === 'A00000') {
                
                this.$message("保存成功")
                this.$store.commit("user/SET_EDIT", false)
                this.$store.dispatch("user/loadMySkillList", {refresh: true})
                
            } else {
                this.$message({
                    type: 'error',
                    message: res.data.msg
                });
            }
            })
        }

    }

}
</script>

<style lang="scss" scoped>
.tag-item {
    position: relative;
    display: inline-block;
    margin-right: 15px;
}
.close-tag {
    position: absolute;
    top: -2px;
    right: -7px;
    font-size: 16px;
    background: #f7f7f7;
    border-radius: 10px;
    cursor: pointer;
} 

.dialog {
    

}
.skill-form {
    padding: 20px;
}

.input-width {
    width: 400px;
}

.tag-input-width {
    width: 100px;
}

.category-item {
    
}

.cover-item {
    
}

.work-item {
    
}
.content-item {
  display: inline-block;
  position: relative;
  margin-right: 30px;
  margin-top: 30px;
}

.content-item-img {
  width: 160px;
  height: 96px;
}

.avatar-box {
  margin-bottom: 20px;
}

input[type=number] {  
    -moz-appearance:textfield;  
}  
input[type=number]::-webkit-inner-spin-button,  
input[type=number]::-webkit-outer-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
}
.delete-bt {
  border-radius: 25px;
  background-color: #efefef;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
}



</style>