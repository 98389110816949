<template>
  <div class="user-container">
        <el-card style="margin-top: 30px;margin-bottom: 15px;">
          <l-base-info></l-base-info>
          
        </el-card>
        <l-my-skill></l-my-skill>
        <l-edit></l-edit>
  </div>
</template>

<script>
import BaseInfo from '../components/user/base-info.vue'
import MySkill from '../components/user/my-skill.vue'
import Edit from '../components/user/edit.vue'

export default {
    components: {
        'l-base-info': BaseInfo,
        'l-my-skill': MySkill,
        'l-edit': Edit,
    }

}
</script>

<style>

</style>