<template>
  <div>
    <el-popover
        style="padding: 0"
        popper-class="my-popover"
        placement="bottom"
        width="400"
        trigger="click"
        v-model="visible">
        <div class="position-container">
            <div class="province-container">
                <div class="province-container-content">
                    <div v-for="first in categoryList" :key="first.id" @mouseenter="hoverFirst(first)" :class="first.class">
                        <div class="province-title">{{first.name}}</div>
                        <div class="province-arrow">></div>
                    </div>
                </div>
                
            </div>
            <div class="city-container">
                <div class="city-container-content">
                    <div v-for="second in currentFirst.children" :key="second.id" @click="selectSecond(second)" class="city-item-box">{{second.name}}</div>
                </div>
                
            </div>
        </div>
        <div slot="reference"><i class="el-icon-menu"></i><label class="touch-popper">{{name}}</label></div>
    </el-popover>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "header",
  props: {
      name: {
          default: '',
          type: String
      },
       from: {
          default: '',
          type: String
      }
  },
  data() {
      return {
          visible: false,
          categoryList: [],
          currentFirst: {},
          currentSecond: {}
      }
  },
  computed: {
    ...mapState({
      
      category: (state) => state.project.currentFirstCategory || {},
    })
  },
  mounted() {
      this.load()
  },
  methods: {
      calCulateProvinceBoxClass() {
          for (let p of this.categoryList) {
            if (this.currentFirst.id === p.id) {
                p.class = "province-item-box province-item-box-selected"
            } else {
                p.class = "province-item-box  province-item-box-no-selected"
            }
          }
             
      },
     
      load() {
          this.axios({
            method: 'get',
            url: '/nrb_service/resource/category',
            
            params: {
                from: this.from
            }
          }).then(res => {
            console.log(res)
            if (res.status === 200) {
              this.categoryList = res.data
              this.currentFirst = this.categoryList[0]
              this.$store.commit("project/selectFirstCategory", this.currentFirst)
              this.calCulateProvinceBoxClass()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })

      },
      hoverFirst(first) {
          this.currentFirst = first
          this.calCulateProvinceBoxClass()
      },
      selectSecond(second) {
          this.currentSecond = second
          
          this.visible = false
          this.$emit("selectFirst", this.currentFirst)
          this.$emit("selectSecond", second)
      }
  }
}
</script>

<style lang="scss">
  .my-popover {
      padding: 0 !important;
  }

  .touch-popper {
      margin-left: 5px;
      cursor: pointer;
  }

 .position-container {
     height: 400px;
     display: flex;

     .province-container {
         width: 140px;
         overflow:hidden;

         .province-container-content {
            background-color: #333;
            width: 160px;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;

            .province-item-box {
                
                padding: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                display: flex;

                .province-title {
                    flex: 1;
                }
            }

            .province-item-box-selected {
                background-color: white;
                color: #333;
            }

            .province-item-box-no-selected {
                background-color: #333;
                color: white;
            }
         }
     }

     .city-container {
         flex: 1;
         overflow: hidden;

         .city-container-content {
            width: 280px;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;

            .city-item-box {
                cursor: pointer;
                padding: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                padding-left: 40px;
            }
         }
     }
 }
</style>>
